import {
  Api,
  AddToCartForm,
  AxfoodCartEntryStatusViewModel,
  CustomerContactForm,
  AddressData,
  ContentType,
} from '@api/storeFrontApi';

const api = new Api({
  baseUrl: '',
});

export const restoreCartAndCloseOpenOrders = (orderID: string) =>
  api.axfood.restoreCartAndCloseOpenOrdersUsingPost({ action: orderID });

export const restoreCart = (action: string) => api.axfood.restoreCartUsingPost({ action });

export const addToCart = async (products: AddToCartForm[], storeId?: string) => {
  const response = await api.axfood.addToCartUsingPost({
    products,
    storeId,
  });
  return response.data;
};

// TODO: https://axfood.atlassian.net/browse/B2C-28347
export interface CartStatus extends AxfoodCartEntryStatusViewModel {
  cartStatus: AxfoodCartEntryStatusViewModel[];
  reason: string;
}
export const getCartStatus = (slotCode?: string, storeId?: string, signal?: AbortSignal) => {
  return api.axfood.checkCartStatusUsingGet(
    {
      slotCode,
      storeId,
    },
    { signal }
  );
};

export const clearCart = (isReplacingOrder = false) =>
  api.axfood.clearCartUsingDelete({
    cancelPossibleContinueCart: isReplacingOrder,
  });

export const setDeliveryMode = (
  deliveryModeCode: 'homeDelivery' | 'pickUpInStore',
  newSuggestedStoreId: string,
  signal?: AbortSignal
) => api.axfood.setDeliveryModeUsingPost(deliveryModeCode, { newSuggestedStoreId }, { signal });

export const getPersistedCart = async () => {
  const response = await api.axfood.getMergablePersistentCartUsingGet();
  return response.data;
};

export const getCart = async () => {
  const response = await api.axfood.getCartUsingGet();
  return response.data;
};

export const setCartContactInfo = async (contactInfo: AddressData) => {
  const response = await api.axfood.setCustomerContactUsingPost(contactInfo as CustomerContactForm);
  return response.data;
};

export const setSlotInCart = (slot: string, isTmsSlot: boolean, tmsDeliveryWindowReference: any, signal: AbortSignal) =>
  api.axfood.setSlotToCartUsingPost(slot, tmsDeliveryWindowReference, { isTmsSlot }, { signal });

export const deleteSlotInCart = (signal?: AbortSignal) => api.axfood.removeSlotFromCartUsingDelete({ signal });

export const getMostLikelyCartAddress = (signal: AbortSignal) =>
  api.axfood.getCustomerLikelyDeliveryAddressUsingGet({ signal });

export const putOrderInCart = (orderID: string) => api.axfood.putToCartUsingPost1(orderID);

export type CartAddressType = {
  addressLine: string;
  addressLine2: string;
  postalCode: string;
  locality: string;
  longitude: number;
  latitude: number;
  firstName: string;
  lastName: string;
  email?: string;
  cellphone: string;
  companyName?: string;
};

export const setAddressOnCart = ({
  addressLine,
  addressLine2,
  cellphone,
  companyName,
  email,
  firstName,
  lastName,
  latitude,
  locality,
  longitude,
  postalCode,
}: CartAddressType) => {
  const data = new FormData();
  data.set('addressLine1', addressLine);
  data.set('addressLine2', addressLine2);
  data.set('postalCode', postalCode);
  data.set('town', locality);
  data.set('longitude', longitude.toString());
  data.set('latitude', latitude.toString());
  data.set('firstName', firstName);
  data.set('lastName', lastName);
  data.set('cellphone', cellphone);

  if (companyName) {
    data.set('companyName', companyName as string);
  }

  if (email) {
    data.set('email', email);
  }

  return api.axfood.setDeliveryAddressUsingPost(data);
};

export const setPostalCodeOnCart = (postalCode: string) => api.axfood.setPostalCodeUsingPost({ postalCode });

export const getHomeDeliverySlots = (postalCode: string, b2bCustomer = false, signal: AbortSignal) =>
  api.axfood.getHomeDeliverySlotsUsingGet({ postalCode, b2b: b2bCustomer }, { signal });

export const getPickUpInStoreSlots = (storeId: string, b2bCustomer = false, signal: AbortSignal) =>
  api.axfood.getPickInStoreSlotsUsingGet(
    {
      storeId,
      b2b: b2bCustomer,
    },
    { signal }
  );

export const setCustomerExtraInfo = (usersOrderReference: string, doorCode: string, commentFromCustomer: string) =>
  api.axfood.setCustomerExtraUsingPost({
    commentFromCustomer,
    doorCode,
    usersOrderReference,
  });

export const replaceEko = (replaceEko: boolean) =>
  api.axfood.updateEkoReplacementFlagUsingPost({
    replaceEko,
  });

export const replaceAll = (replace: boolean[]) => {
  const data = new FormData();
  replace.forEach((item, i) => data.append(`entries[${i}]`, (!item).toString()));
  return api.axfood.updateNoReplacementFlagUsingPost({
    type: ContentType.FormData,
    // @ts-ignore
    body: data,
  });
};
