import { RefObject, useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@hooks/useAppDispatch';
import useTranslation from 'next-translate/useTranslation';
import useResizeObserver from '@react-hook/resize-observer';
import { toggleMenu } from '@slices/menuSlice';
import CMSNavNode from '@molecules/CMSNavNode/CMSNavNode';
import AccordionNavNode from '@molecules/AccordionNavNode/AccordionNavNode';
import Backdrop from '@atoms/Backdrop/Backdrop';
import useCustomRouter from '@hooks/useCustomRouter';
import { selectSideNavMenuIsOpen } from '@slices/menuSlice';
import { StyledSideNav, StyledSideNavHeading, StyledSideNavSection } from './SideNav.styles';
import useCategoryNavigationTree from '@organisms/SideNav/useCategoryNavigationTree';
import useResponsive from '@hooks/useResponsive';

interface Props {
  headerRef: RefObject<HTMLDivElement> | null;
  linksNode: CMSNode;
}

const getLinkTitle = (link: CMSNavNodeLink) => {
  return link.linkName[0]?.value;
};

const SideNav = ({ headerRef, linksNode }: Props) => {
  const Router = useCustomRouter();
  const stickyToolbarHeight = 60;
  const [offsetHeight, setOffsetHeight] = useState(0);
  const { t } = useTranslation('sidenav');
  const isOpen: boolean = useAppSelector(selectSideNavMenuIsOpen);
  const [expandedNavigationNodes, setExpandedNavigationNodes] = useState<Array<NavigationNode>>([]);
  const dispatch = useAppDispatch();
  const { data: categories, isLoading } = useCategoryNavigationTree();
  const [activeNode, setActiveNode] = useState<ActiveNodeType>({ id: '', parents: [] });
  const [expandedNodes, setExpandedNodes] = useState<Array<NavigationNode>>([]);
  const sideNavRef = useRef<HTMLDivElement | null>(null);
  const { fromDesktop } = useResponsive();
  const showBackdrop = !fromDesktop && isOpen;

  const closeHandler = () => {
    localStorage.setItem('willys.sidenav-left', (!isOpen).toString());
    dispatch(toggleMenu(!isOpen));
  };

  const scrollHandler = () => {
    if (offsetHeight && sideNavRef.current) {
      if (fromDesktop) {
        if (window.scrollY >= offsetHeight - stickyToolbarHeight) {
          sideNavRef.current.style.height = `calc(100vh - ${stickyToolbarHeight}px)`;
        } else {
          sideNavRef.current.style.height = `calc(100vh - ${offsetHeight - window.scrollY}px)`;
        }
      } else {
        sideNavRef.current.style.height = '';
      }
    }
  };

  let continueExec = true;
  const getActiveCategory = (node: AccordionNavNodeType, parentNodes: NavigationNode[]) => {
    if (continueExec) {
      const currentHierarchy = parentNodes.concat({ id: node.category });
      if (Router.asPath.split('?')[0] === node.url) {
        continueExec = false;
        setActiveNode({ id: node.category, parents: parentNodes });
        setExpandedNodes(parentNodes.filter((n) => n.id).length > 0 ? currentHierarchy : []);
      } else {
        node?.children?.forEach((child) => getActiveCategory(child, currentHierarchy));
      }
    }
  };

  const getActiveCMSNode = (node: CMSNode, parentNodes: NavigationNode[]) => {
    if (continueExec) {
      const currentHierarchy = parentNodes.concat({ id: node.title });
      const activeLink = node.links?.find((link: CMSNavNodeLink) => {
        if (link.url === '/erbjudanden') {
          return Router.route.startsWith('/erbjudanden');
        }

        return link.url === Router.asPath.split('?')[0];
      });

      if (activeLink) {
        continueExec = false;
        setActiveNode({ id: getLinkTitle(activeLink), parents: parentNodes });
        setExpandedNodes(parentNodes.filter((n) => n.id).length > 0 ? currentHierarchy : []);
      } else {
        node?.children?.forEach((child) => getActiveCMSNode(child, currentHierarchy));
      }
    }
  };

  useEffect(() => {
    if (!isLoading) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      continueExec = true;
      if (categories) {
        getActiveCategory(categories, [] as NavigationNode[]);
      }
      if (linksNode) {
        getActiveCMSNode(linksNode, [] as NavigationNode[]);
      }

      if (continueExec && categories && linksNode) {
        setActiveNode({ id: '', parents: [] });
        setExpandedNodes([]);
        setExpandedNavigationNodes([]);
      }
    }
  }, [Router.asPath, isLoading]);

  useResizeObserver(headerRef, (entry) => {
    setOffsetHeight(entry.contentRect.height);
    scrollHandler();
  });

  useEffect(() => {
    if (headerRef?.current?.getBoundingClientRect) {
      setOffsetHeight(headerRef.current.getBoundingClientRect().height);
      scrollHandler();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headerRef]);

  useEffect(() => {
    document.addEventListener('scroll', scrollHandler);
    return () => {
      document.removeEventListener('scroll', scrollHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromDesktop]);

  useEffect(() => {
    // Focus on first button in the sidenav when it opens, for A11y
    if (isOpen) {
      const firstButton = sideNavRef.current?.querySelector('button');
      firstButton?.focus();
    }
  }, [isOpen]);

  return (
    <>
      {showBackdrop && <Backdrop display={showBackdrop} callback={closeHandler} />}
      <StyledSideNav ref={sideNavRef} isOpen={isOpen} aria-hidden={isOpen ? 'false' : 'true'} role="menu">
        {!isLoading && linksNode && (
          <CMSNavNode
            node={linksNode}
            parentNodes={[]}
            setActiveNode={setActiveNode}
            activeNode={activeNode as ActiveNodeType}
            setExpandedNodes={setExpandedNodes}
            expandedNodes={expandedNodes as NavigationNode[]}
            navigationNodes={expandedNavigationNodes}
            setNavigationNodes={setExpandedNavigationNodes}
          />
        )}
        {!isLoading && (
          <StyledSideNavSection>
            <StyledSideNavHeading variant="h3" label>
              {t('sidenav->header->categories')}
            </StyledSideNavHeading>
            <ul>
              {categories &&
                categories.children.map((child) => {
                  return (
                    <AccordionNavNode
                      node={child}
                      parentNodes={[]}
                      key={`node-child-${child.title}`}
                      noBorder={false}
                      navigationNodes={expandedNavigationNodes}
                      setNavigationNodes={setExpandedNavigationNodes}
                      setActiveNode={setActiveNode}
                      activeNode={activeNode as ActiveNodeType}
                      setExpandedNodes={setExpandedNodes}
                      expandedNodes={expandedNodes as NavigationNode[]}
                    />
                  );
                })}
            </ul>
          </StyledSideNavSection>
        )}
      </StyledSideNav>
    </>
  );
};

export default SideNav;
