import { useAppDispatch } from '@hooks/useAppDispatch';
import useCustomRouter from '@hooks/useCustomRouter';
import { trackOpenCmsLink } from '@helpers/analyticsHelpers/trackMenuAction';
import { toggleMenu } from '@slices/menuSlice';
import { useMediaQuery } from 'react-responsive';
import Config from '@config';
import usePromotionPageRouting from '@hooks/usePromotionPageRouting';
import Icon from '@atoms/Icon/Icon';
import { useCallback } from 'react';
import {
  StyledCmsNavNode,
  StyledCmsNavNodeHeading,
  StyledCmsNavNodeHeadingButton,
  StyledCmsNavNodeIconWrapper,
  StyledCmsNavNodeItem,
  StyledCmsNavNodeLink,
  StyledCmsNavNodeLinkButton,
  StyledCmsNavNodeTitle,
} from './CMSNavNode.styles';
import PlusSmall from '@icons/plus_small.svg';
import MinusSmall from '@icons/minus_small.svg';
interface Props {
  node: CMSNode;
  parentNodes: Array<NavigationNode>;
  showNode?: boolean;
  setActiveNode: (node: ActiveNodeType) => void;
  activeNode: ActiveNodeType;
  setExpandedNodes: (nodes: Array<NavigationNode>) => void;
  expandedNodes: Array<NavigationNode>;
  noBorder?: boolean;
  navigationNodes: Array<NavigationNode>;
  setNavigationNodes: (navigationNodes: Array<NavigationNode>) => void;
}

const getLinkTitle = (link: CMSNavNodeLink) => {
  return link?.linkName[0]?.value;
};

const CMSNavNode = ({
  node,
  parentNodes,
  showNode,
  setActiveNode,
  activeNode,
  setExpandedNodes,
  expandedNodes,
  noBorder,
  navigationNodes,
  setNavigationNodes,
}: Props) => {
  const Router = useCustomRouter();
  const dispatch = useAppDispatch();
  const hasChildren: boolean = node?.children?.length > 0;
  const isTopLevel: boolean = parentNodes?.length === 0;
  const currentNodeHierarchy: Array<NavigationNode> = parentNodes.concat({ id: node.title });
  const isActive = (link: CMSNavNodeLink) => activeNode.id === getLinkTitle(link);
  const { promotionTab } = usePromotionPageRouting({ enableRouting: false });
  const fromDesktop = useMediaQuery({
    query: Config.BREAKPOINTS.FROM_DESKTOP,
  });

  const currentNodeExpanded = (currentNode: CMSNode) => {
    return expandedNodes.map((n) => n?.id).includes(currentNode.title) && !isTopLevel;
  };

  const navigationNodeExpanded = (currentNode: CMSNode) => {
    return navigationNodes.map((n: NavigationNode) => n?.id).includes(currentNode.title);
  };

  const isHighlighted = (currentNode: CMSNode) => {
    const highlightedList = currentNode.links.filter((l) => getLinkTitle(l) === activeNode.id);
    return highlightedList.length > 0;
  };

  const onClickHandler = (localNode: CMSNode): any => {
    if (currentNodeExpanded(localNode)) {
      setExpandedNodes([]);
      setNavigationNodes([]);
    } else if (node.title === activeNode.id) {
      setExpandedNodes([{ id: localNode.title }]);
    } else {
      navigationNodeExpanded(localNode) ? setNavigationNodes([]) : setNavigationNodes([{ id: localNode.title }]);
    }
  };

  const hasActiveLink = (n: CMSNode) => {
    return n.links?.some((link) => link.url === Router.asPath);
  };

  const getActiveChild = useCallback((): any => {
    let activeChild;
    let i = 0;

    while (!activeChild && i < node.children?.length) {
      activeChild = hasActiveLink(node.children[i]) ? node.children[i] : null;
      i += 1;
    }
    return activeChild;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [node.children]);

  const setActiveLink = useCallback(() => {
    const activeLink = node.links?.find((link) => link.url === Router.asPath);

    if (activeLink) {
      setActiveNode({ id: getLinkTitle(activeLink), parents: parentNodes });
      setExpandedNodes([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [node.links, Router.asPath]);

  const setActiveAndExpand = () => {
    setActiveLink();
    const activeChild = getActiveChild();
    if (activeChild) {
      setExpandedNodes(currentNodeHierarchy.concat(activeChild.title));
    }
  };

  const dispatchActiveAndExpand = (link: string) => {
    setActiveNode({ id: link, parents: parentNodes });
    trackOpenCmsLink(link);
    if (!fromDesktop) {
      localStorage.setItem('willys.sidenav-left', 'false');
      dispatch(toggleMenu(false));
    }
  };

  return (
    <StyledCmsNavNode
      hasChildren={hasChildren}
      showNode={showNode as boolean}
      isTopLevel={isTopLevel}
      isExpanded={currentNodeExpanded(node) || navigationNodeExpanded(node)}
    >
      {node.links?.map((link) => {
        return (
          <StyledCmsNavNodeItem
            data-testid={'nav-node-item'}
            hasChildren={false}
            key={`link-${getLinkTitle(link)}-${link.url}`}
            noBorder={noBorder}
          >
            <StyledCmsNavNodeLinkButton
              type="button"
              role="menuitem"
              aria-label="menuitem"
              onClick={() => {
                setActiveNode({ id: '', parents: [] });
                setExpandedNodes([]);
                setActiveAndExpand();
                dispatchActiveAndExpand(getLinkTitle(link));
              }}
            >
              <StyledCmsNavNodeLink
                isExpanded={
                  navigationNodeExpanded(node) || (currentNodeExpanded(node) && parentNodes[0]?.id !== undefined)
                }
                isActive={isActive(link)}
                variant="sidenav"
                href={link.url}
              >
                {getLinkTitle(link)}
              </StyledCmsNavNodeLink>
            </StyledCmsNavNodeLinkButton>
          </StyledCmsNavNodeItem>
        );
      })}
      {hasChildren &&
        node.children.map((child) => {
          const isCurrentNodeExpanded = currentNodeExpanded(child);
          const isNavigationNodeExpanded = navigationNodeExpanded(child);
          const isNodeHighlighted = isHighlighted(child);
          return (
            <StyledCmsNavNodeItem
              data-testid={'nav-node-item'}
              hasChildren={child.children.length > 0 && !isTopLevel}
              isExpanded={isCurrentNodeExpanded}
              isNested
              noBorder={noBorder}
              key={`child-${getLinkTitle(child?.links[0])}`}
            >
              {isTopLevel && (
                <StyledCmsNavNodeHeading variant="h3" label>
                  {child.title}
                </StyledCmsNavNodeHeading>
              )}
              {!isTopLevel && (
                <StyledCmsNavNodeHeadingButton
                  type="button"
                  role="menuitem"
                  aria-label="menuitem"
                  onClick={() => onClickHandler(child)}
                  adjustPadding={isCurrentNodeExpanded}
                >
                  <StyledCmsNavNodeTitle isExpanded={isCurrentNodeExpanded || isNodeHighlighted}>
                    {child.title}
                  </StyledCmsNavNodeTitle>
                  <StyledCmsNavNodeIconWrapper isExpanded={isCurrentNodeExpanded}>
                    <Icon
                      svg={isCurrentNodeExpanded || isNavigationNodeExpanded ? MinusSmall : PlusSmall}
                      size={12}
                      color={isCurrentNodeExpanded || isNodeHighlighted ? 'primary' : 'lighterBlack'}
                      data-testid={isCurrentNodeExpanded || isNavigationNodeExpanded ? 'icon-minus' : 'icon-plus'}
                    />
                  </StyledCmsNavNodeIconWrapper>
                </StyledCmsNavNodeHeadingButton>
              )}
              <CMSNavNode
                node={child}
                parentNodes={currentNodeHierarchy}
                showNode={!child.children.length && child.links.length > 0 && isTopLevel}
                setActiveNode={setActiveNode}
                activeNode={activeNode}
                setExpandedNodes={setExpandedNodes}
                expandedNodes={expandedNodes}
                noBorder={currentNodeExpanded(child)}
                navigationNodes={navigationNodes}
                setNavigationNodes={setNavigationNodes}
              />
            </StyledCmsNavNodeItem>
          );
        })}
    </StyledCmsNavNode>
  );
};

export default CMSNavNode;
