import Head from 'next/head';
import React from 'react';
import { CategoryInfoType } from './CategoryProductBannerComponent';
import useTranslation from 'next-translate/useTranslation';

interface Props {
  categoryInfo: CategoryInfoType;
}

const CategoryProductBannerComponentHead = ({ categoryInfo }: Props) => {
  const { t } = useTranslation('categorypage');

  return (
    <Head>
      <title>
        {categoryInfo.parentCategoryName
          ? t('meta->titleWithParent', {
              parent: categoryInfo.parentCategoryName,
              name: categoryInfo.name,
            })
          : t('meta->title', { name: categoryInfo.name })}
      </title>

      <meta
        name="description"
        content={
          categoryInfo.parentCategoryName
            ? t('meta->descriptionWithParent', {
                parentCategory: categoryInfo.parentCategoryName,
                assortment: categoryInfo.name,
              })
            : t('meta->description', {
                assortment: categoryInfo.name,
              })
        }
      />
    </Head>
  );
};

export default CategoryProductBannerComponentHead;
