import { ReactNode, useRef, useState } from 'react';
import { useAppSelector } from '@hooks/useAppDispatch';
import Head from 'next/head';
import paths from '@constants/paths';
import ScrollTop from '@molecules/ScrollTop/ScrollTop';
import Footer from '@organisms/Footer/Footer';
import Toolbar from '@organisms/Toolbar/Toolbar';
import SideNav from '@organisms/SideNav/SideNav';
import OpenOrderNotice from '@atoms/OpenOrderNotice/OpenOrderNotice';
import useAxiosInterceptors from '@hooks/useAxiosInterceptors';
import { selectMiniCartPreviewIsOpen } from '@slices/miniCartSlice';
import CMSHead from '@utility/CMSHead/CMSHead';
import OrderCloseTimePassedModal from '@molecules/OrderCloseTimePassedModal/OrderCloseTimePassedModal';
import { selectSideNavMenuIsOpen } from '@slices/menuSlice';
import useCustomRouter from '@hooks/useCustomRouter';
import useResponsive from '@hooks/useResponsive';
import useUserAgent from '@hooks/useUserAgent';
import CheckoutEmptyCartModal from '@molecules/CheckoutEmptyCartModal/CheckoutEmptyCartModal';
import Overlay from '@molecules/Overlay/Overlay';
import { selectToolbarSearchIsExpanded } from '@slices/toolbarSlice';
import LoginPrompt from '@molecules/LoginPrompt/LoginPrompt';
import { ComponentAdaptedData } from '@api/generated/cms';
import collectSlots, { ContentSlotWsDTOWithComponent } from '@api/interfaces/collectSlots';
import FaqWidget from '@molecules/FaqWidget/FaqWidget';
import HeaderContainer from '@molecules/HeaderContainer/HeaderContainer';
import useDisplayStates from '@hooks/useDisplayStates';
import {
  StyledBodyContainer,
  StyledContentWrapper,
  StyledMainContainer,
  StyledMainContainerSection,
} from './Layout.styles';
import useProductDetailsRouting from '@hooks/useProductDetailsRouting';
import useTracking from '@hooks/useTracking';
import HotJarStyleChanger from '@molecules/HotJarStyleChanger/HotJarStyleChanger';
import useCmsPage from '@hooks/useCmsPage';
import { gothamBook, gothamMedium, gothamSerifBold, gothamSerifPrice } from '@/fonts';
import dynamic from 'next/dynamic';
import useCart from '@hooks/useCart';
import { layoutSlotList } from '@helpers/cmsTemplateHelper';

interface Props {
  label?: string;
  children: ReactNode;
}

const DynamicCartModals = dynamic(() => import('@components/Layout/CartModals' /* webpackChunkName: 'CartModals' */));
const DynamicProductDetails = dynamic(
  () => import('@organisms/ProductDetails/ProductDetails' /* webpackChunkName: 'ProductDetails' */)
);

const Layout = ({ label, children }: Props) => {
  // Hooks
  const { cmsPage } = useCmsPage(label || 'fallbackpage');
  const router = useCustomRouter();
  const { isMobile } = useResponsive();
  const headerRef = useRef<HTMLDivElement>(null);
  const { mobileSearchVisible, showScrollToTop, hideFaqWidget, enableLoginPrompt } = useDisplayStates();
  const { cart } = useCart();
  const hasProducts = Boolean(cart?.products.length);
  useAxiosInterceptors();
  useTracking();

  // Redux
  const sideMenuIsOpen = useAppSelector(selectSideNavMenuIsOpen);
  const cartPreviewIsOpen = useAppSelector(selectMiniCartPreviewIsOpen);
  const isSearchExpanded = useAppSelector(selectToolbarSearchIsExpanded);

  // State
  const { isNativeApp, isWebOrNativeAndEcommerce, isReactNative, isIOS } = useUserAgent();
  const [isCheckout, setIsCheckout] = useState(false);
  const [isSharedWishList, setIsSharedWishList] = useState(false);
  const [canonicalLink, setCanonicalLink] = useState('');

  // Slots
  const { HeaderLeft, HeaderCenter, NavigationLeft, FooterCenter, MessageBox } = collectSlots(
    cmsPage?.slots,
    layoutSlotList
  );
  const HeaderSlots = [HeaderLeft, HeaderCenter].filter((slot): slot is ContentSlotWsDTOWithComponent => Boolean(slot));
  const sideNavNode =
    NavigationLeft?.component[0] && (NavigationLeft?.component[0] as ComponentAdaptedData).navigationNode;

  // Routing
  const { productDetailVisibility } = useProductDetailsRouting({
    setIsSharedWishList,
    setCanonicalLink,
    setIsCheckout,
  });

  return (
    <>
      <CMSHead cmsPage={cmsPage} />
      <Head>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content={`width=device-width, initial-scale=1, minimum-scale=1, ${
            isIOS || isReactNative ? 'maximum-scale=1, ' : ''
          }viewport-fit=cover`}
        />
        <link rel="canonical" href={canonicalLink} />
      </Head>
      <StyledBodyContainer
        addBottomPadding={hasProducts && isMobile && router.pathname.startsWith(paths.CART)}
        className={`${gothamSerifPrice.className} ${gothamSerifBold.className} ${gothamMedium.className} ${gothamBook.className}`}
      >
        <HeaderContainer
          messageSlot={MessageBox}
          headerSlots={HeaderSlots}
          headerRef={headerRef}
          isCheckout={isCheckout}
          isSharedWishList={isSharedWishList}
        />

        {isWebOrNativeAndEcommerce && !isCheckout && !isSharedWishList && <Toolbar headerRef={headerRef} />}

        <StyledContentWrapper hide={mobileSearchVisible}>
          {isSearchExpanded && !isMobile && <Overlay />}
          {NavigationLeft && isWebOrNativeAndEcommerce && !isCheckout && !isSharedWishList && (
            <SideNav headerRef={headerRef} linksNode={sideNavNode as unknown as CMSNode} />
          )}
          <StyledMainContainer
            sideNav={!isCheckout && !isSharedWishList && sideMenuIsOpen}
            cartPreview={!isCheckout && !isSharedWishList && cartPreviewIsOpen}
          >
            {productDetailVisibility && <DynamicProductDetails />}
            <StyledMainContainerSection>
              {!isCheckout && !isSharedWishList && <OpenOrderNotice />}
              {children}
            </StyledMainContainerSection>
          </StyledMainContainer>
          <DynamicCartModals headerRef={headerRef} />
          <OrderCloseTimePassedModal />
          {!isNativeApp && <CheckoutEmptyCartModal />}
        </StyledContentWrapper>
        {FooterCenter && !isNativeApp && !isSharedWishList && !mobileSearchVisible && <Footer slot={FooterCenter} />}
      </StyledBodyContainer>

      {!mobileSearchVisible && showScrollToTop && <ScrollTop productDetailsOpen={productDetailVisibility} />}

      <FaqWidget hideWidget={showScrollToTop || hideFaqWidget || !!isReactNative || !!isNativeApp} />
      <HotJarStyleChanger />

      {enableLoginPrompt && <LoginPrompt isCheckout={isCheckout} />}
    </>
  );
};

export default Layout;
