
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    /* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useAppDispatch, useAppSelector } from '@hooks/useAppDispatch';
import useTranslation from 'next-translate/useTranslation';
import { setDeliveryPickerIsOpen } from '@slices/deliveryPickerSlice';
import CMSHead from '@utility/CMSHead/CMSHead';
import Text from '@atoms/Text/Text';
import Container from '@atoms/Container/Container';
import Alert from '@molecules/Alert/Alert';
import Page from '@organisms/Page/Page';
import useCustomer from '@hooks/useCustomer';
import useCustomRouter from '@hooks/useCustomRouter';
import Config from '@config';
import PromotionOffersFragment from '@molecules/PromotionOffersFragment/PromotionOffersFragment';
import { selectDeliveryPicker } from '@slices/deliveryPickerSlice';
import { selectPromotionStoreId, setPromotionPageStoreId } from '@slices/promotionPageSlice';
import { addDays } from 'date-fns';
import { parseDateQuery } from '@helpers/formatDate';
import ErrorComponent from '@molecules/ErrorComponent/ErrorComponent';
import useDidUpdateEffect from '@hooks/useDidUpdateEffect';
import debounce from '@helpers/debounce';
import { trackLoadMore } from '@helpers/analyticsHelpers/trackPromotionPage';
import { selectSideNavMenuIsOpen } from '@slices/menuSlice';
import { selectMiniCartPreviewIsOpen } from '@slices/miniCartSlice';
import usePromotionPageRouting from '@hooks/usePromotionPageRouting';
import PromotionPreviewContent from '@organisms/PromotionPreviewContent/PromotionPreviewContent';
import { getPromotionStoreId } from '@helpers/localStorageFacade';
import { StyledClosedStoreAlert, StyledPromotionPageContent, StyledPromotionPageHeading } from './PromotionPage.styles';
import usePromotionCampaigns from '@pages/erbjudanden/usePromotionCampaigns';
import createFallback, { oneDayInSeconds } from '@helpers/fallback';
import useCmsPage from '@hooks/useCmsPage';
import PromotionTabs from '@molecules/PromotionTabs/PromotionTabs';
import { FacetSearchPageData } from '@api/generated/storefront';
import useStore from '@hooks/useStore';
import ProductBeamSkeleton from '@molecules/ProductBeam/ProductBeam.skeleton';
export const defaultPreviewDateRange = { from: new Date(), to: addDays(new Date(), 7) };
export const GENERAL_CAMPAIGNS = 'PERSONAL_GENERAL';
export const PERSONAL_CAMPAIGNS = 'PERSONAL_SEGMENTED';
export const MAPPED_TABS = {
    online: 'ehandel' as PromotionTabType,
    offline: 'butik' as PromotionTabType,
};
const label = 'WillysPromotionPage';
const PromotionPage = () => {
    const sideMenuIsOpen = useAppSelector(selectSideNavMenuIsOpen);
    const isCartPreviewOpen = useAppSelector(selectMiniCartPreviewIsOpen);
    const router = useCustomRouter();
    const dispatch = useAppDispatch();
    const { store } = useStore();
    const promotionStoreId = useAppSelector(selectPromotionStoreId);
    const deliveryPicker = useAppSelector(selectDeliveryPicker);
    const { customer } = useCustomer();
    const { t } = useTranslation('promotionPage');
    const [previewDateRange, setPreviewDateRange] = useState(parseDateQuery(router.query));
    const { promotionTab, activeTab, isPreview, selectedOfflineStore, setSelectedOfflineStore } = usePromotionPageRouting({
        enableRouting: true,
    });
    const [showProductsWithoutImages, setShowProductsWithoutImages] = useState(false);
    const [page, setPage] = useState(0);
    const [combinedGeneralCampaigns, setCombinedGeneralCampaigns] = useState<FacetSearchPageData>({} as FacetSearchPageData);
    const storeSelected = promotionTab === Config.ROUTES.promotionpage.store;
    const ecommerceSelected = promotionTab === Config.ROUTES.promotionpage.ecommerce;
    const { personalCampaigns, generalCampaigns, isLoading, error } = usePromotionCampaigns(isPreview, page, showProductsWithoutImages);
    const { cmsPage } = useCmsPage(label);
    const hasMore = !isLoading &&
        !!combinedGeneralCampaigns.pagination?.totalNumberOfResults &&
        !!combinedGeneralCampaigns?.results?.length &&
        !!combinedGeneralCampaigns?.pagination?.currentPage &&
        combinedGeneralCampaigns?.pagination?.totalNumberOfResults > combinedGeneralCampaigns?.results?.length &&
        combinedGeneralCampaigns?.pagination?.currentPage > 0;
    const openDeliveryPicker = useCallback(() => {
        if (deliveryPicker.isOpen)
            return;
        dispatch(setDeliveryPickerIsOpen({ isOpen: true }));
    }, [deliveryPicker.isOpen]);
    const loadMore = async (storeType: onlineOfflineType | 'preview/offline', track: boolean, reset?: boolean) => {
        if (track)
            trackLoadMore(storeType as onlineOfflineType);
        if (reset) {
            setPage(0);
        }
        else if (combinedGeneralCampaigns?.pagination?.currentPage !== undefined) {
            setPage(combinedGeneralCampaigns.pagination.currentPage + 1);
        }
        return null;
    };
    useEffect(() => {
        setPage(0);
    }, [customer?.isAnonymous, store?.storeId, router?.query?.q]);
    const InfiniteScrollFragment = (storeType: onlineOfflineType) => (<InfiniteScroll dataLength={combinedGeneralCampaigns?.results?.length || 0} next={() => loadMore(storeType, false)} hasMore={hasMore} style={{ overflow: undefined }} loader={undefined}>
      <PromotionOffersFragment storeType={storeType} generalCampaigns={combinedGeneralCampaigns} personalCampaigns={personalCampaigns} isLoading={isLoading} loadMore={loadMore}/>
    </InfiniteScroll>);
    /**
     * Set offline store id on mount
     */
    useEffect(() => {
        const id = getPromotionStoreId() || customer?.homeStoreId || '';
        dispatch(setPromotionPageStoreId(id));
    }, [customer?.isAnonymous]);
    useEffect(() => {
        if (generalCampaigns) {
            setCombinedGeneralCampaigns((prevState: any) => {
                if (page === 0)
                    return generalCampaigns;
                return {
                    ...prevState,
                    pagination: generalCampaigns?.pagination,
                    results: [...prevState?.results, ...(generalCampaigns?.results || [])],
                };
            });
        }
    }, [generalCampaigns]);
    const debounceLoadMore = debounce(() => loadMore('offline', false, true), Config.TIMEOUT.promotionPageLoadMoreMs);
    useDidUpdateEffect(() => {
        if (!customer?.isAnonymous && isPreview) {
            debounceLoadMore();
        }
        return () => debounceLoadMore.cancel();
    }, [previewDateRange, showProductsWithoutImages, customer?.isAnonymous]);
    if (error)
        return <ErrorComponent errorCode={error.response?.status}/>;
    return (<>
      <CMSHead cmsPage={cmsPage}/>
      {!isPreview ? (<Page cmsPage={cmsPage}>
          <Container addPaddingIfCartOrMenuOpen={sideMenuIsOpen || isCartPreviewOpen}>
            <StyledPromotionPageContent>
              <StyledPromotionPageHeading variant="h1">{t('promotionPage:heading')}</StyledPromotionPageHeading>
              <PromotionTabs activeTab={activeTab} ecommerceSelected={ecommerceSelected} setPage={setPage} openDeliveryPicker={openDeliveryPicker} setSelectedOfflineStore={setSelectedOfflineStore} selectedOfflineStore={selectedOfflineStore}/>

              {storeSelected && selectedOfflineStore?.open === false && (<StyledClosedStoreAlert>
                  <Alert id="storeNotOpenYet" hasCloseButton={false} theme="info">
                    <Text type="body" size="small">
                      {t('promotionPage:storeNotOpenYet')}
                    </Text>
                  </Alert>
                </StyledClosedStoreAlert>)}

              {storeSelected && promotionStoreId && selectedOfflineStore?.open && InfiniteScrollFragment('offline')}
              {ecommerceSelected && store?.storeId && InfiniteScrollFragment('online')}
              {isLoading && !ecommerceSelected && !storeSelected && <ProductBeamSkeleton />}
            </StyledPromotionPageContent>
          </Container>
        </Page>) : (<PromotionPreviewContent setPreviewDateRange={setPreviewDateRange} offersPreview={combinedGeneralCampaigns} previewDateRange={previewDateRange} isLoading={isLoading} loadMore={loadMore} setShowProductsWithoutImages={setShowProductsWithoutImages} showProductsWithoutImages={showProductsWithoutImages}/>)}
    </>);
};
export function getStaticPaths() {
    return {
        paths: [{ params: { mode: 'ehandel' } }, { params: { mode: 'butik' } }],
        // controls the behavior when a URL doesn't match any of the paths defined
        fallback: false, // return 404
    };
}
async function getStaticProps({ locale = 'sv' }) {
    const fallback = await createFallback('ContentPage', label);
    return {
        props: {
            fallback,
            label,
        },
        revalidate: oneDayInSeconds,
    };
}
export default PromotionPage;

    async function __Next_Translate__getStaticProps__191c223caa1__(ctx) {
      const res = await getStaticProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/erbjudanden/PromotionPage.tsx',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__191c223caa1__ as getStaticProps }
  