import Head from 'next/head';
import { ErrorInfo, useEffect, useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { useMediaQuery } from 'react-responsive';
import useUserAgent from '@hooks/useUserAgent';
import useCustomRouter from '@hooks/useCustomRouter';
import Config from '@config';
import Button from '@atoms/Button/Button';
import Text from '@atoms/Text/Text';
import Heading from '@atoms/Heading/Heading';
import Link from '@atoms/Link/Link';
import Breadcrumbs from '@molecules/Breadcrumbs/Breadcrumbs';
import ProductQuantityInputField from '@molecules/ProductQuantityInputField/ProductQuantityInputField';
import Modal, { ModalProps } from '@organisms/Modal/Modal';
import ErrorBoundary from '@utility/ErrorBoundary/ErrorBoundary';
import logger from '@logger';
import { StyledEnergyDeclarationImage } from '@molecules/ProductTableOfOther/ProductTableOfOther.styles';
import { isOfflineProduct, isOfflinePromotion, isOnlineProduct } from '@helpers/typeGuards/product';
import ProductDetailsTabs from './ProductDetailsTabs/ProductDetailsTabs';
import ProductDetailsPriceAndPromotionDetails from './ProductDetailsPriceAndPromotionDetails/ProductDetailsPriceAndPromotionDetails';
import ProductDetailsAccordion from './ProductDetailsAccordion/ProductDetailsAccordion';
import ProductDetailsRelated from './ProductDetailsRelated/ProductDetailsRelated';
import {
  BreadcrumbsWrapper,
  StyledNotFoundText,
  StyledProductDetails,
  StyledProductDetailsEnergyDeclaration,
  StyledProductDetailsHeadingWrapper,
  StyledProductDetailsManufacturer,
  StyledProductDetailsManufacturerLink,
  StyledProductDetailsManufacturerVolume,
  StyledProductDetailsManufacturerWrapper,
  StyledProductDetailsPriceAndPromotion,
  StyledProductDetailsQuantityField,
  StyledProductDetailsRight,
  StyledProductDetailsRightBottom,
  StyledProductDetailsRightTop,
  StyledProductDetailsWrapper,
  StyledSpinner,
} from './ProductDetails.styles';
import ProductLabelSplash, { LabelSplashType } from '@molecules/ProductLabelSplash/ProductLabelSplash';
import ProductDetailsLeftSection from '@organisms/ProductDetails/ProductDetailsLeftSection/ProductDetailsLeftSection';
import usePromotionPageRouting from '@hooks/usePromotionPageRouting';
import type { AxfoodOfflineProductViewModel, AxfoodProductDetailsViewModel } from '@api/storeFrontApi';
import useProduct from '@organisms/ProductDetails/useProduct';
import GridContainer from '@organisms/GridContainer/GridContainer';
import trackProductDetail from '@helpers/analyticsHelpers/trackProductDetail';

const errorHandler =
  (product: AxfoodProductDetailsViewModel | AxfoodOfflineProductViewModel | undefined) =>
  (error: Error, info: ErrorInfo) => {
    if (product) {
      logger.error({
        error: `Product Detail (${isOnlineProduct(product) ? product.code : product.name}) Error boundary caught error`,
        message: error.message,
        ...info,
      });
    }
  };

interface Props {
  isPage?: boolean;
}

const ProductDetails = ({ isPage = false }: Props) => {
  const router = useCustomRouter();
  const [quantity, setQuantity] = useState<number | null>(null);
  const { t } = useTranslation('product');
  const { product, isLoading, error, productLabelSplashType } = useProduct();
  const firstPromotion = product?.potentialPromotions && product.potentialPromotions[0];
  const { promotionTab } = usePromotionPageRouting({ enableRouting: false });

  const isMobile = useMediaQuery({
    query: Config.BREAKPOINTS.IS_MOBILE,
  });
  const { isNativeApp } = useUserAgent();

  const closeModal = () => {
    const { name, productCode, tab, showInModal, ...query } = router.query;
    let { pathname } = router;

    const isPromotionsPath = pathname.indexOf('/erbjudanden') > -1;
    const isPromotionsPreviewPath = pathname.indexOf('/erbjudanden/preview') > -1;

    if (isPromotionsPreviewPath) {
      pathname = '/erbjudanden/preview';
    } else if (isPromotionsPath) {
      if (query.mode !== 'butik' && query.mode !== 'ehandel') {
        query.mode = promotionTab || Config.ROUTES.promotionpage.ecommerce;
      }
      pathname = '/erbjudanden/[mode]';
    }

    router.push({ pathname, query }, undefined, { shallow: true, scroll: false, nativeAppMethodOverride: 'replace' });
  };

  useEffect(() => {
    router.beforePopState(({ as }) => {
      if (as !== router.asPath) {
        closeModal();
        return false;
      }
      return true;
    });

    return () => {
      router.beforePopState(() => true);
    };
  });

  useEffect(() => {
    if (!isLoading && !error && router.isReady && product) {
      trackProductDetail(product);
    }
  }, [isLoading, error, product, router.isReady]);

  let modalProps: Omit<ModalProps, 'children'> = {
    onClose: closeModal,
    size: 'large',
    hasCloseIcon: true,
  };

  if (error) {
    modalProps = {
      ...modalProps,
      id: 'product-not-found-modal',
      verticallyCentered: true,
      size: 'medium',
    };
  } else {
    modalProps = {
      ...modalProps,
      id: 'product-modal',
      isProductModal: true,
      noPadding: true,
    };
  }

  const productContent = (
    <ErrorBoundary errorHandler={errorHandler(product)}>
      {isLoading && <StyledSpinner size={32} />}
      {!isLoading && error && (
        <>
          <Heading variant="h2" size="small">
            {t('product->not_found->heading')}
          </Heading>
          <StyledNotFoundText>
            <Text type="body">{t('product->not_found->body')}</Text>
          </StyledNotFoundText>
          <Button centered onClick={closeModal}>
            {t('product->not_found->cta')}
          </Button>
        </>
      )}
      {!isLoading && product && (
        <StyledProductDetailsWrapper itemScope itemType="https://schema.org/Product">
          {isOnlineProduct(product) && <meta itemProp="description" content={product.description} />}
          {!isOfflineProduct(product) && !isNativeApp && product?.breadCrumbs?.length > 0 && isPage && (
            <BreadcrumbsWrapper isPage>
              <Breadcrumbs breadcrumbs={product.breadCrumbs.slice(1, product.breadCrumbs.length - 1)} />
            </BreadcrumbsWrapper>
          )}
          <StyledProductDetails>
            <ProductDetailsLeftSection product={product} quantity={quantity} isPage={isPage} />
            <StyledProductDetailsRight isPage={isPage}>
              <StyledProductDetailsRightTop>
                {!isOfflineProduct(product) && !isNativeApp && product?.breadCrumbs?.length > 0 && !isPage && (
                  <BreadcrumbsWrapper>
                    <Breadcrumbs breadcrumbs={product.breadCrumbs.slice(1, product.breadCrumbs.length - 1)} />
                  </BreadcrumbsWrapper>
                )}
                <StyledProductDetailsHeadingWrapper>
                  <Heading variant="h2">
                    <span itemProp="name">{product?.name}</span>
                  </Heading>
                </StyledProductDetailsHeadingWrapper>
                {productLabelSplashType && (
                  <ProductLabelSplash type={productLabelSplashType as LabelSplashType} blockElement />
                )}
                <StyledProductDetailsManufacturerWrapper>
                  {isOfflineProduct(product) && product.manufacturer && (
                    <StyledProductDetailsManufacturer itemProp="brand">
                      {product?.manufacturer}
                    </StyledProductDetailsManufacturer>
                  )}
                  {isOnlineProduct(product) && product.manufacturer && (
                    <StyledProductDetailsManufacturerLink
                      href={{
                        pathname: '/sok',
                        query: { q: `${product.manufacturer}:topRated:commercialName2:${product.manufacturer}` },
                      }}
                      internal
                      prefetch={false}
                    >
                      <span itemProp="brand">{product.manufacturer}</span>
                    </StyledProductDetailsManufacturerLink>
                  )}
                  {isOnlineProduct(product) && product.fruitAndVegetableVariety && product.productLine2 && (
                    <StyledProductDetailsManufacturerVolume>
                      {`${product.fruitAndVegetableVariety}, ${product.productLine2}`}
                    </StyledProductDetailsManufacturerVolume>
                  )}
                  {(isOfflineProduct(product) || !product.fruitAndVegetableVariety) && (
                    <StyledProductDetailsManufacturerVolume>
                      {product.displayVolume && product.displayVolume}
                      {isOnlineProduct(product) &&
                        product.originCountry &&
                        `${product.displayVolume ? ',' : ''} ${product.originCountry}`}
                    </StyledProductDetailsManufacturerVolume>
                  )}
                </StyledProductDetailsManufacturerWrapper>

                {isOfflineProduct(product) &&
                  firstPromotion &&
                  isOfflinePromotion(firstPromotion) &&
                  firstPromotion.description && <Text type="body">{firstPromotion.description}</Text>}

                <StyledProductDetailsPriceAndPromotion>
                  <ProductDetailsPriceAndPromotionDetails product={product} />
                </StyledProductDetailsPriceAndPromotion>

                {isOfflineProduct(product) && product?.energyDeclaration && (
                  <StyledProductDetailsEnergyDeclaration data-testid="energyDeclaration">
                    <Heading variant="h3" label>
                      {t('product->energyDeclaration->heading')}
                    </Heading>

                    {product.energyDeclaration.link && (
                      <Text type="body">
                        <Link href={product.energyDeclaration.link} data-testid="energyDeclaration_link" target="_blank">
                          {t('product->energyDeclaration->linkText')}
                        </Link>
                      </Text>
                    )}

                    {product.energyDeclaration.image && (
                      <StyledEnergyDeclarationImage
                        alt={t('product->energyDeclaration->heading')}
                        src={product.energyDeclaration.image}
                        data-testid="energyDeclaration_image"
                      />
                    )}
                  </StyledProductDetailsEnergyDeclaration>
                )}

                {isOnlineProduct(product) && (
                  <>
                    <StyledProductDetailsQuantityField>
                      <ProductQuantityInputField product={product} variant="card" quantityCallback={setQuantity} />
                    </StyledProductDetailsQuantityField>
                    {!isMobile && <ProductDetailsTabs data={product} />}
                  </>
                )}
              </StyledProductDetailsRightTop>

              {isOnlineProduct(product) && (
                <StyledProductDetailsRightBottom>
                  {isMobile && <ProductDetailsAccordion data={product as AxfoodProductDetailsViewModel} />}
                </StyledProductDetailsRightBottom>
              )}
            </StyledProductDetailsRight>
          </StyledProductDetails>
          {isOnlineProduct(product) && <ProductDetailsRelated product={product} isPage={isPage} />}
          <>
            <div itemProp="offers" itemType="http://schema.org/Offer" itemScope>
              <meta
                itemProp="availability"
                content={
                  isOnlineProduct(product) && product.outOfStock
                    ? 'https://schema.org/OutOfStock'
                    : 'https://schema.org/InStock'
                }
              />
              <meta itemProp="priceCurrency" content="SEK" />
              <meta itemProp="price" content={product.priceNoUnit} />
            </div>
          </>
        </StyledProductDetailsWrapper>
      )}
    </ErrorBoundary>
  );

  const productWrapper = () => {
    if (isPage) {
      return (
        <GridContainer size="large" noPadding={isMobile}>
          {productContent}
        </GridContainer>
      );
    }
    return <Modal {...modalProps}>{productContent}</Modal>;
  };

  return (
    <>
      {product && (
        <>
          <Head>
            <title>
              {product?.manufacturer
                ? t('metadata:pdp->meta->titleWithManufacturer', {
                    name: product.name,
                    volume: product.displayVolume,
                    manufacturer: product.manufacturer,
                  })
                : t('metadata:pdp->meta->title', { name: product.name, volume: product.displayVolume })}
            </title>
            <meta
              name="description"
              content={t('metadata:pdp->meta->description', { name: product.name, volume: product.displayVolume })}
            />
          </Head>
        </>
      )}
      {productWrapper()}
    </>
  );
};

export default ProductDetails;
