import { ErrorInfo, useMemo } from 'react';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import Image from 'next/image';
import slugify from '@helpers/slugify';
import Link from '@atoms/Link/Link';
import ProductQuantityInputField from '@molecules/ProductQuantityInputField/ProductQuantityInputField';
import PriceLabel from '@molecules/PriceLabel/PriceLabel';
import ErrorBoundary from '@utility/ErrorBoundary/ErrorBoundary';
import logger from '@logger';
import { isOfflinePromotion } from '@helpers/typeGuards/product';
import {
  StyledFooterCTAWrapper,
  StyledLowestHistoricalPrice,
  StyledProduct,
  StyledProductComparePrice,
  StyledProductFooter,
  StyledProductFooterBottomSection,
  StyledProductHeader,
  StyledProductLinkWrapper,
  StyledProductManufacturerBrand,
  StyledProductManufacturerVolumeWrapper,
  StyledProductName,
  StyledProductOriginalPrice,
  StyledProductPriceWrapper,
  StyledProductPromotionPriceInfo,
  StyledProductSavePrice,
} from './SlimProductCard.styles';
import type { AxfoodBasicProductViewModel, AxfoodProductDetailsViewModel } from '@api/storeFrontApi';
import { useProductImage } from '@hooks/useProductImage';

export type Variant = 'product' | 'mixmatch' | 'multisearch';

interface ProductProps {
  product: AxfoodProductDetailsViewModel | AxfoodBasicProductViewModel;
  category?: string;
  offline?: boolean;
  variant?: Variant;
  className?: string;
  showBuyButtonForZeroQuantity?: boolean;
}

const errorHandler = (code: string) => (error: Error, info: ErrorInfo) => {
  logger.error({
    error: `Product (${code}) Error boundary caught error`,
    message: error.message,
    ...info,
  });
};

const SlimProductCard = ({
  product,
  category,
  offline,
  variant = 'product',
  className,
  showBuyButtonForZeroQuantity = false,
}: ProductProps) => {
  const router = useRouter();
  const { t } = useTranslation('product');
  const promotion = product?.potentialPromotions[0];
  const hasSavePriceLabel = !!promotion?.conditionLabel?.length;
  const hasPromotion = !!promotion;
  const hasComparePrices = (product?.comparePrice !== '' && product?.comparePriceUnit) || null;
  const hasPromotionComparePrices = !!promotion?.comparePrice;
  const isPromotionPage = router.pathname === '/erbjudanden' || router.pathname === '/erbjudanden/[mode]';
  const lowestHistoricalPrice = promotion?.lowestHistoricalPrice;
  const { productImageURL, productImageAltText, onImageLoadError } = useProductImage(product);
  const hasMixMatch = promotion?.realMixAndMatch;

  const url = `${isPromotionPage ? (offline ? 'offline-' : 'online-') : ''}${slugify(product.name)}-${
    offline ? promotion?.code : product.code
  }`;
  let as = `/produkt/${encodeURI(url)}`;

  const query = { ...router.query, name: encodeURIComponent(url), productCode: product.code, showInModal: true };

  const manufacturer = useMemo(() => {
    let str = (product?.manufacturer || '').trim();
    // Fix problem where admins add a period instead of empty string
    if (str === '.') str = '';
    return str;
  }, [product?.manufacturer]);
  const displayVolume = useMemo(() => (product?.displayVolume || '').trim(), [product?.displayVolume]);

  return (
    <ErrorBoundary errorHandler={errorHandler(product.code)}>
      <StyledProduct
        data-testid="product"
        offline={offline}
        variant={variant}
        className={className}
        itemScope
        itemType="https://schema.org/Product"
      >
        <StyledProductHeader>
          <Link
            href={{ pathname: router.pathname, query }}
            nativeAppMethodOverride="replace"
            asProp={{ pathname: as }}
            scroll={false}
            internal
            shallow
          >
            <StyledProductLinkWrapper>
              <Image
                priority
                unoptimized
                itemProp="image"
                alt={productImageAltText}
                src={productImageURL}
                width="56"
                height="56"
                onError={onImageLoadError}
              />
            </StyledProductLinkWrapper>
          </Link>

          <StyledProductPriceWrapper>
            {hasSavePriceLabel && (!lowestHistoricalPrice || lowestHistoricalPrice?.value === product.priceValue) && (
              <StyledProductSavePrice>
                {hasMixMatch
                  ? promotion?.conditionLabelFormatted
                    ? promotion?.conditionLabelFormatted
                    : promotion?.conditionLabel
                  : promotion?.conditionLabel}
              </StyledProductSavePrice>
            )}
            <PriceLabel product={product} variant="list" />
          </StyledProductPriceWrapper>
        </StyledProductHeader>

        <StyledProductFooter>
          <StyledProductName itemProp="name">{product.name}</StyledProductName>
          {(manufacturer || displayVolume) && (
            <StyledProductManufacturerVolumeWrapper>
              <StyledProductManufacturerBrand itemProp="brand">
                {!!manufacturer && manufacturer} {!!displayVolume && displayVolume}
              </StyledProductManufacturerBrand>
            </StyledProductManufacturerVolumeWrapper>
          )}

          <StyledFooterCTAWrapper>
            <StyledProductFooterBottomSection>
              {hasPromotion && hasPromotionComparePrices && !offline && (
                <StyledProductPromotionPriceInfo>
                  {`${t('product->price->compare')} ${
                    promotion.comparePrice && promotion.comparePrice.indexOf('/') > -1
                      ? promotion.comparePrice
                      : `${promotion.comparePrice}/${product.comparePriceUnit}`
                  }`}

                  {`${promotion?.redeemLimitLabel ? ` • ${promotion?.redeemLimitLabel}` : ''}`}
                </StyledProductPromotionPriceInfo>
              )}
              {hasPromotion && !offline && (
                <StyledProductOriginalPrice>
                  {`${t('product->price->default')} ${product?.priceNoUnit} ${product?.priceUnit}`}
                </StyledProductOriginalPrice>
              )}
              {!hasPromotion && !hasPromotionComparePrices && hasComparePrices && (
                <StyledProductComparePrice>
                  {`${t('product->price->compare')} ${product?.comparePrice}/${product?.comparePriceUnit}`}
                </StyledProductComparePrice>
              )}
              {offline && hasPromotion && (
                <>
                  {isOfflinePromotion(promotion) && promotion?.savePrice && (
                    <StyledProductPromotionPriceInfo>{promotion?.savePrice}</StyledProductPromotionPriceInfo>
                  )}
                  {promotion?.redeemLimitLabel && (
                    <StyledProductOriginalPrice>{promotion?.redeemLimitLabel}</StyledProductOriginalPrice>
                  )}
                </>
              )}
              {!!lowestHistoricalPrice && (
                <StyledLowestHistoricalPrice>
                  {t('product->price->lowestHistorical', {
                    price: lowestHistoricalPrice?.formattedValue,
                  })}
                </StyledLowestHistoricalPrice>
              )}
            </StyledProductFooterBottomSection>

            <ProductQuantityInputField
              product={product}
              variant="cart"
              category={category}
              listName={category}
              mixmatch={variant === 'mixmatch'}
              showBuyButtonForZeroQuantity={showBuyButtonForZeroQuantity}
              sizeOverride="small"
            />
          </StyledFooterCTAWrapper>
        </StyledProductFooter>
        <div itemProp="offers" itemType="http://schema.org/Offer" itemScope>
          <link itemProp="url" href={as} />
          <meta
            itemProp="availability"
            content={product.outOfStock ? 'https://schema.org/OutOfStock' : 'https://schema.org/InStock'}
          />
          <meta itemProp="priceCurrency" content="SEK" />
          <meta itemProp="price" content={product.priceNoUnit} />
        </div>
        <meta itemProp="description" content={product.productLine2} />
      </StyledProduct>
    </ErrorBoundary>
  );
};

export default SlimProductCard;
