import { Api } from '@api/storeFrontApi';

const originalFetch: typeof fetch = global.fetch;

interface CustomFetch extends Function {
  (input: RequestInfo | URL, init?: RequestInit): Promise<Response>;
}

let csrfToken: string;

const api = new Api({
  baseUrl: '',
});

/**
 * This is needed since we patch global fetch, without this we will pass
 * csrf-tokens on all third party requests
 */
const isOwnDomain = (hostname: string) => {
  //TODO: add domains
  const ownDomains = ['localhost', 'axfood.se', 'local.se', 'willys.se'];
  return ownDomains.some((domain) => hostname === domain || hostname.endsWith('.' + domain));
};

global.fetch = async function (input: RequestInfo | URL, init?: RequestInit): Promise<Response> {
  const url =
    // @ts-ignore
    typeof input === 'string' ? new URL(input, window.location.origin) : new URL(input.url, window.location.origin);
  if (!isOwnDomain(url.hostname)) {
    return originalFetch(input, init);
  }
  let [resource, options] = [input, init];

  // Conditionally add csrf token header
  if (csrfToken) {
    options = {
      ...options,
      headers: {
        ...options?.headers,
        'X-CSRF-Token': csrfToken,
      },
    };
  }

  const response = await originalFetch(resource, options);
  if (response.status === 401) {
    const data = await response.json();
    if (data && data.error === 'csrf.badormissing') {
      const csrfResponse = await api.axfood.getCsrfTokenUsingGet();
      csrfToken = await csrfResponse.json();
      return fetch(input, options);
    }
  }
  return response;
} as CustomFetch;
