import { useState, useEffect, useCallback } from 'react';
import ProductContainer from '@molecules/ProductContainer/ProductContainer';
import LoadMore from '@molecules/LoadMore/LoadMore';
import packageComponentHelper from '@helpers/packageComponentHelper';
import { useMediaQuery } from 'react-responsive';
import Config from '@config';
import Text from '@atoms/Text/Text';
import Heading from '@atoms/Heading/Heading';
import getBeamListName from '@helpers/analyticsHelpers/getBeamListName';
import { useRouter } from 'next/router';
import ProductBeamSkeleton from '@molecules/ProductBeam/ProductBeam.skeleton';
import { StyledHeading, StyledAlert } from './ProductBeam.styles';
import useGridTemplateColumns from '@hooks/useGridTemplateColumns';
import FilterAndSort from '@organisms/FilterAndSort/FilterAndSort';
import trackSort from '@helpers/analyticsHelpers/trackSort';
import { AxfoodProductDetailsViewModel } from '@api/generated/storefront';
import useProductBannerItems from '@molecules/ProductBeam/useProductBannerItems';
import useResponsive from '@hooks/useResponsive';

interface Props {
  data: CMSProductBannerComponentType;
  displayFilters?: boolean;
}

const sortPackageComponents = (packageComponents: any) => {
  return Object.keys(packageComponents)
    .map((key) => {
      return {
        ...packageComponents[key],
        typeCode: packageComponents[key].componentType,
        image: {
          mobile: packageComponents[key].mobileImage,
          tablet: packageComponents[key].tabletImage,
          desktop: packageComponents[key].desktopImage,
        },
      };
    })
    .sort((a, b) => {
      if (a.displayRow < b.displayRow) {
        return -1;
      }
      if (a.displayRow > b.displayRow) {
        return 1;
      }
      return 0;
    });
};

const LOAD_AMOUNT_OF_ROWS = 5;
const ProductBeam = ({ data, displayFilters }: Props) => {
  const router = useRouter();
  const { component, packages, isLoading } = useProductBannerItems(data.uid, displayFilters);
  const { isMobile, isTablet } = useResponsive();
  const [ref, setRef] = useState<HTMLDivElement | null>(null);
  const handleRef = useCallback((node: HTMLDivElement) => {
    setRef(node);
  }, []);
  const [sortOptions, setSortOptions] = useState<OptionType[] | null>(null);
  const getSortOptions = (sorts: SortType[]): OptionType[] => {
    return sorts?.map((sort) => {
      const { code: value, name, selected } = sort;
      return { value, label: name, selected };
    });
  };
  const getSelectedSort = () => sortOptions?.find((s) => s.selected);
  const onSort = (option: OptionType) => {
    const selectedSort = getSelectedSort();
    if (selectedSort && option.value !== selectedSort.value) {
      const queryParams = { ...router.query, sort: option.value };
      trackSort('my_most_bought_page', option?.label);
      router.push({ pathname: router.pathname, query: queryParams });
    }
  };
  const packageHelper = packageComponentHelper();
  const gridPackageData = sortPackageComponents(packages || {});
  const [itemsPerRow, setItemsPerRow] = useState(0);
  const [productLimit, setProductLimit] = useState(0);
  const [productBannerData, setProductBannerData] = useState<ProductBannerComponentType | null>(null);
  const [emptyProductListData, setEmptyProductListData] = useState<{
    emptyProductListTitle: string;
    emptyProductListBody: string;
    isEmpty: boolean;
  }>({
    emptyProductListTitle: '',
    emptyProductListBody: '',
    isEmpty: false,
  });
  const [missingProductBannerData, setMissingProductBannerData] = useState({
    isMissing: false,
  });
  const [items, setItems] = useState<Array<GridPackageComponentType | AxfoodProductDetailsViewModel>>([]);
  useGridTemplateColumns(setItemsPerRow, ref, isLoading);

  const getResponsiveNumberOfRows = (): number => {
    return parseInt(
      isMobile
        ? data.numberOfRowsMobile || data.numberOfRowsTablet || data.numberOfRows
        : isTablet
        ? data.numberOfRowsTablet || data.numberOfRows
        : data.numberOfRows,
      10
    );
  };

  const [numberOfRows, setNumberOfRows] = useState<number>(getResponsiveNumberOfRows() || 1);

  useEffect(() => {
    setNumberOfRows(getResponsiveNumberOfRows());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile, isTablet]);

  const getCurrentMediaQueryKey = () => {
    return isMobile ? 's' : isTablet ? 'm' : 'l';
  };

  const calculateLimit = (nrOfRows: number) => {
    if (itemsPerRow === 0) return;
    let reduceLimitBy = 0;
    gridPackageData?.forEach((packageComp: ImageGridComponentType) => {
      if (parseInt(packageComp.displayRow, 10) <= nrOfRows) {
        reduceLimitBy += packageHelper.getSpanSize(packageComp)[getCurrentMediaQueryKey()] - 1;
      }
    });

    setProductLimit(nrOfRows * itemsPerRow - reduceLimitBy);
  };

  useEffect(() => {
    if (component) {
      if (component.paginationData) {
        setProductBannerData(component);
        if (displayFilters && component.sorts) {
          setSortOptions(getSortOptions(component.sorts));
        }
      }

      if (component.componentNotFound) {
        setMissingProductBannerData({ isMissing: true });
      }

      if (component.emptyProductListTitle || component.emptyProductListBody) {
        setEmptyProductListData({
          isEmpty: true,
          emptyProductListTitle: component.emptyProductListTitle,
          emptyProductListBody: component.emptyProductListBody,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [component, packages]);

  useEffect(() => {
    if (itemsPerRow !== 0 && productBannerData) {
      const sortedItems = packageHelper.positionPackagesInList(
        productBannerData.paginationData?.items,
        gridPackageData,
        getCurrentMediaQueryKey(),
        itemsPerRow
      );
      setItems(sortedItems);
      calculateLimit(numberOfRows);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsPerRow, productBannerData]);

  if (missingProductBannerData.isMissing) {
    return null;
  }

  if (emptyProductListData.isEmpty) {
    return (
      <StyledAlert id="emptyListMsg" theme="permanent" hasCloseButton={false}>
        {emptyProductListData.emptyProductListTitle && (
          <Heading variant="h2">{emptyProductListData.emptyProductListTitle}</Heading>
        )}
        {emptyProductListData.emptyProductListBody && <Text>{emptyProductListData.emptyProductListBody}</Text>}
      </StyledAlert>
    );
  }

  return (
    <>
      {productBannerData && !isLoading && (
        <div ref={handleRef}>
          {productBannerData?.title && productBannerData?.paginationData?.items ? (
            <StyledHeading variant="h2" size="small">
              {productBannerData?.title}{' '}
            </StyledHeading>
          ) : null}
          {displayFilters && (
            <FilterAndSort
              filterOptions={productBannerData.facets}
              selectedFilters={productBannerData.breadcrumbs}
              sortOptions={sortOptions}
              onSort={onSort}
              totalResults={productBannerData.paginationData.items?.length}
              gtmTrackOnFilterOpened={false}
              forceHideRelevanceTooltip={true}
            />
          )}
          {!isLoading && Object.prototype.hasOwnProperty.call(productBannerData, 'paginationData') && (
            <>
              <ProductContainer
                title={productBannerData.title}
                products={items}
                category={displayFilters ? data?.uid : getBeamListName(productBannerData.title, router)}
                productType="online"
                numberOfGridCols={itemsPerRow}
                limit={productLimit}
              />
              <LoadMore
                pageSize={LOAD_AMOUNT_OF_ROWS}
                total={items.length}
                limit={productLimit}
                onClick={() => {
                  setNumberOfRows(numberOfRows + LOAD_AMOUNT_OF_ROWS);
                  calculateLimit(numberOfRows + LOAD_AMOUNT_OF_ROWS);
                }}
                loading={isLoading}
                showProgress
              />
            </>
          )}
        </div>
      )}
      {isLoading && <ProductBeamSkeleton />}
    </>
  );
};

export default ProductBeam;
